/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=VT323&display=swap"); */

body {
  /* font-family: "VT323",
  monospace; */
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
:root {
  --primary-color: #fff;
  --secondary-color: rgb(2, 132, 199);
  --brand-dark: rgb(13, 15, 58);
  --brand-primary: rgb(11, 110, 209);
  --background: rgb(245, 245, 245);
}

html {
  /* background-color: var(--brand-dark); */
}

.site-wrapper {
  background-color: var(--background);
}

.default-button {
  @apply bg-brandDark hover:bg-darkFaded  text-white rounded shadow py-2 px-4  text-sm flex gap-2 whitespace-nowrap;
}

.toggle-checkbox:checked {
  @apply: right-0 border-blue-900;
  right: 0;
  border-color: rgb(236, 72, 153);
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-blue-900;
  background-color: rgb(236, 72, 153);
}

.sidebar {
  /* z-index: 39; */
  /* width: 66px;
  min-width: 66px; */
}

.add-shadow {
  @apply shadow-sm;
}

.sidebar.open {
  /* @apply lg:w-1/5; */
  width: 320px;
}

@screen lg {
  .p-c {
    padding-left: 320px;
  }
}
.sidebar-filler {
  @apply hidden;
  @apply lg:block;
  /* @apply lg:w-1/5; */
  width: 320px;
}

.custom-picker-wrapper {
  @apply border-2 !important;
  @apply border-gray-900 !important;
}

.page-content {
}

.paginator {
  @apply relative z-0 flex flex-wrap md:flex-nowrap md:inline-flex rounded-md shadow-sm -space-x-px;
}

.paginator li {
  /* @apply flex; */
  /* @apply hidden sm:list-item; */
}

.paginator li a {
  @apply bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium;
}

.paginator li.next a,
.paginator li.previous a {
  @apply relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
}

.paginator li.selected a,
.paginator li.selected a {
  @apply z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium;
}

.profile-button img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100% !important;
  max-width: none !important;
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
}

.article-content h2 {
  @apply text-2xl font-bold mb-4;
}
.article-content h3 {
  @apply text-lg font-bold mb-2;
}
.article-content p {
  @apply mb-4;
}
