.ReactModal__Overlay {
  z-index: 100;
}

.authbackground {
}
.banner-col {
  background-image: url(./images/bg.jpg);
  position: relative;
  display: flex;
  height: 100%;
  flex: 1 1 0%;
  flex-direction: column;
  background-size: cover;
  background-position: 50%;
  background-position: top;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.sticky-bit {
  top: 0px;
  z-index: 10;
}

.tab-button.react-tabs__tab--selected {
  @apply bg-brandPrimary;
  @apply text-white;
}
.tab-button {
  cursor: pointer;
}
.tab-button:hover {
  @apply bg-darkFaded;
  @apply text-white;
}

.ElementsApp {
  @apply border;
  @apply border-gray-700;
  @apply p-2;
}

#card-element .InputContainer {
  border: 1px solid #eee;
}

.blink {
  animation: blink-animation 4s infinite;
}

.blink-faster {
  animation: blink-animation-faster 3s infinite;
  @apply font-bold;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink-animation-faster {
  0% {
    opacity: 1;
    @apply text-blue-900;
  }
  50% {
    opacity: 0.5;
    @apply text-pink-900;
  }
  100% {
    opacity: 1;
    @apply text-blue-900;
  }
}

.react-time-picker__wrapper {
  @apply border;
  @apply border-gray-300;
  @apply p-1;
  @apply rounded-md;
}

.add-slot .react-datepicker-wrapper {
  @apply w-full;
}
